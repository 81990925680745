import { useContext, useEffect } from "react";
import "./Home.css";
import { UserContext } from "../../../Redux/UserContext";
import { useNavigate } from "react-router-dom";
import { OrdersState, TasksDispatch, TasksState } from "../../../Redux/OrdersContext";
import { useDispatch, useSelector } from "react-redux";
import TaskModel from "../../../Models/TaskModel";
import { updateTask } from "../../../Redux/slicers/tasksSlicer";
import OrderModel from "../../../Models/OrderModel";
import { changePickedOrder } from "../../../Redux/slicers/ordersSlicer";

function Home(): JSX.Element {
	const context = useContext(UserContext);
	const navigate = useNavigate();
	const twoDaysAgo = new Date();
	twoDaysAgo.setDate(twoDaysAgo.getDate() - 1);

	const orders = useSelector((state: OrdersState) => state.orders.value);
	const readyNotPaid = orders.filter((order) =>
		order.pickup_branch !== 7 &&
		order.is_ready_for_pickup &&
		!order.is_paid &&
		new Date(order.pickup_date) < twoDaysAgo);
	const tasks = useSelector((state: TasksState) => state.tasks.value);
	const dispatch: TasksDispatch = useDispatch();

	// Check if user is logged in
	useEffect(() => {
		if (context.user === null) {
			navigate("/login");
		}
	}, [context.user, navigate]);

	async function markAsDone(task: TaskModel) {
		const updatedTask = { ...task, is_done: true };
		try {
			dispatch(updateTask(updatedTask));
		} catch (err) {
			alert("Failed to mark task as done: " + err);
		}
	}

	const showEditOrder = (order: OrderModel) => {
		dispatch(changePickedOrder(order));
		const editOrder = document.getElementById("EditOrder");
		if (editOrder) {
			editOrder.classList.add("visible");
		}
	};

	const branchMap = [
		"הזמנות דחופות",       // 0
		"ביג",              	// 1
		"דיסק קלאב",           // 2
		"אייסמול",             // 3
		"מתחם רד",             // 4
		"טיילת",               // 5
		"משלוח בתוך אילת",    // 6
		"משלוח בית לקוח",     // 7
		"מחסן ראשי"           // 8, main warehouse
	];

	const formatDate = (expense_date: String) => {
		const date = new Date(expense_date.toString());
		const startDay = date.getDate().toString().padStart(2, "0");
		let startMonth = date.getMonth() + 1;
		// let startYear = date.getFullYear();

		// Format startMonth and endMonth with leading zeros
		const startMonthStr = startMonth.toString().padStart(2, "0");

		// Extract the last two digits of the startYear and endYear
		// const startYearStr = startYear.toString().slice(-2);

		return `${startDay}/${startMonthStr}`;
	};

	return (
		<div className="Home Box">
			<h1> Rafi Tracky </h1>
			<h3>כלי למעקב יעיל ומהיר על כל ההזמנות הפעילות</h3>
			<br />
			<h2>משימות פתוחות</h2>
			<div className="TasksContent">
				{tasks.slice(0, 5).map((task) => (
					<div className="card text-white bg-dark mb-3" key={task.id}>
						<div className="card-body">
							<h5 className="card-title">{task.description}</h5>
						</div>
						<div className="card-footer">
							<button className="button-29" onClick={() => markAsDone(task)}>בוצע</button>
						</div>
					</div>
				))}
			</div>
			<br />
			{readyNotPaid.length > 0 && <h2>הזמנות פתוחות שלא שולמו</h2>}
			<div className="OrdersContent">
				{readyNotPaid.map((order) => (
					<div className="card text-white bg-dark mb-3 readyNotPaid" key={order.id}>
						<div className="card-header">{order.order_number}</div>
						<div className="card-header">{order.customer_name}</div>
						<div className="card-body">
							<h5 className="card-title">{order.description}</h5>
						</div>
						<div className="card-footer">
							<p className="card-text">תאריך איסוף: {formatDate(order.pickup_date)}</p>
							<p className="card-text">סניף איסוף: {branchMap[order.pickup_branch]}</p>
							<p className="card-text">{Number(order.total_price).toLocaleString("en-US")} ₪</p>
						</div>
						<div className="card-footer">
							<button className="button-29" onClick={() => showEditOrder(order)}>טיפול</button>
						</div>
					</div>

				))
				}
			</div>
		</div>
	)
}

export default Home;
