import { useEffect, useState } from "react";
import "./EditOrder.css";
import { useDispatch, useSelector } from "react-redux";
import { OrdersDispatch, OrdersState } from "../../Redux/OrdersContext";
import { useForm } from "react-hook-form";
import OrderModel from "../../Models/OrderModel";
import { updateOrder, deleteOrder, setInputFocusTyping } from "../../Redux/slicers/ordersSlicer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


function EditOrder(): JSX.Element {
    let pickedOrder = useSelector((state: OrdersState) => state.orders.pickedOrder);
    const dispatch: OrdersDispatch = useDispatch();
    const currentDate = new Date();
    const [totalPrice, setTotalPrice] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [pickupDate, setPickupDate] = useState(currentDate);
    const [orderDate, setOrderDate] = useState(currentDate);
    const [isTransferChecked, setIsTransferChecked] = useState(false);
    const { register, handleSubmit, formState, setValue, setError } = useForm<OrderModel>();
    function handleTotalPriceChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value.replace(/[^\d.]/g, "");
        setTotalPrice(value);
        if (formState.errors.total_price) {
            formState.errors.total_price.message = "";
        }
    }

    function handlePhoneNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value.replace(/[^\d.]/g, "");
        setPhoneNumber(value);
        if (formState.errors.total_price) {
            formState.errors.total_price.message = "";
        }
    }

    function handleTransferChange() {
        setIsTransferChecked(!isTransferChecked);
    }

    // Disable input fields in date picker and handle click outside of the form
    useEffect(() => {
        document.querySelectorAll(".react-datepicker__input-container input").forEach((input) => { (input as HTMLInputElement).readOnly = true });
        const backgroundHandler = document.getElementById("EditOrder");
        const handleClick = (e: MouseEvent) => {
            if (e.target === backgroundHandler) {
                closeEditOrder();
            }
        };
        const escapeKey = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                closeEditOrder();
            }
        };
        if (backgroundHandler) {
            backgroundHandler.addEventListener("click", handleClick);
        }
        window.addEventListener("keydown", escapeKey);
        // Cleanup event listener on component unmount
        return () => {
            if (backgroundHandler) {
                backgroundHandler.removeEventListener("click", handleClick);
            }
        };
    });



    useEffect(() => {
        if (pickedOrder) {
            setValue("order_number", pickedOrder.order_number);
            setValue("pickup_date", pickedOrder.pickup_date);
            setPickupDate(new Date(pickedOrder.pickup_date));
            setValue("pickup_branch", pickedOrder.pickup_branch);
            setValue("customer_name", pickedOrder.customer_name);
            setPhoneNumber(pickedOrder.customer_phone.replace("+972", "0"));
            setValue("customer_email", pickedOrder.customer_email);
            setValue("description", pickedOrder.description);
            setValue("total_price", pickedOrder.total_price);
            setTotalPrice(pickedOrder.total_price.toString());
            setValue("order_date", pickedOrder.order_date);
            setOrderDate(new Date(pickedOrder.order_date));
            setValue("is_paid", pickedOrder.is_paid);
            setValue("transfer", pickedOrder.transfer);
            setIsTransferChecked(pickedOrder.transfer.valueOf());
            setValue("transfer_branch", pickedOrder.transfer_branch);
            setValue("is_transferred", pickedOrder.is_transferred);
            setValue("is_ready_for_pickup", pickedOrder.is_ready_for_pickup);
        }
    }, [pickedOrder, setValue]);

    function closeEditOrder() {
        const backgroundHandler = document.getElementById("EditOrder");
        if (backgroundHandler) {
            backgroundHandler.classList.remove("visible");
        }
        dispatch(setInputFocusTyping(true));
    }

    async function wipeOrder() {
        const isConfirmed = window.confirm("Are you sure you want to delete this order?");

        if (isConfirmed) {
            try {
                dispatch(deleteOrder(pickedOrder.id));
                closeEditOrder();
            } catch (err) {
                alert(err);
            }
        }
    }

    async function send(order: OrderModel) {
        order.total_price = parseFloat(totalPrice);
        order.pickup_date = pickupDate.toISOString().split("T")[0];
        order.order_date = orderDate.toISOString().split("T")[0];
        order.customer_phone = phoneNumber;
        if (isTransferChecked && order.is_ready_for_pickup && !order.is_transferred) {
            setError("is_ready_for_pickup", { "message": "הזמנה עם העברה לא יכולה להיות מוכנה לאיסוף" });
            return;
        }
        try {
            pickedOrder = { ...order, id: pickedOrder.id }
            dispatch(updateOrder(pickedOrder));
            closeEditOrder();
        } catch (err) {
            alert(err);
        }
    }


    return (
        <div className="EditOrder" id="EditOrder">
            <div className="EditOrderC">
                <h3> פרטי הזמנה</h3>
                <i className="fa fa-times" onClick={closeEditOrder}></i>
                <form>
                    <p className="error-message">
                        {formState.errors.is_ready_for_pickup ? formState.errors.is_ready_for_pickup.message : null}
                    </p>

                    <div className="form-row">
                        {/* Order Number */}
                        <div className="input-data">
                            <input type="text" required {...register("order_number", {
                                required: {
                                    value: true,
                                    message: "שדה חובה"
                                }
                            })} />
                            <div className="underline"></div>
                            <label>מספר הזמנה</label>
                        </div>
                        {/* Pick Up Date */}
                        <div className="input-data">
                            <span className="date-label">תאריך איסוף</span>
                            <DatePicker
                                selected={pickupDate}
                                onChange={(date) => (setPickupDate(date))}
                                dateFormat="dd/MM"
                                className="react-datepicker__input-container"
                            />
                            <input
                                type="hidden"
                                {...register("pickup_date", { required: true })}
                                value={pickupDate.toISOString().split("T")[0]}
                            />
                        </div>
                        {/* Pick Up Branch */}
                        <div className="input-data">
                            <select required className="select-style" {...register("pickup_branch", {
                                required: {
                                    value: true,
                                    message: "שדה חובה"
                                }
                            })}>
                                <option value="1">ביג</option>
                                <option value="2">דיסק קלאב</option>
                                <option value="3">אייסמול</option>
                                <option value="4">רד</option>
                                <option value="5">טיילת</option>
                                <option value="6">משלוח לבית הלקוח באילת</option>
                                <option value="7">משלוח לבית הלקוח </option>
                                <option value="8">מחסן ראשי </option>
                            </select>
                            <label>סניף איסוף</label>
                        </div>
                    </div>

                    <div className="form-row">
                        {/* Customer Name */}
                        <div className="input-data">
                            <input type="text" required {...register("customer_name", {
                                required: {
                                    value: true,
                                    message: "שדה חובה"
                                }
                            })} />
                            <div className="underline"></div>
                            <label>שם לקוח</label>
                        </div>
                        {/* Customer Phone */}
                        <div className="input-data">
                            <input type="text" required value={phoneNumber} {...register("customer_phone", {
                                required: {
                                    value: true,
                                    message: "שדה חובה"
                                },
                                onChange: (e) => { handlePhoneNumberChange(e) },
                            })} />
                            <div className="underline"></div>
                            <label>טלפון לקוח</label>
                        </div>
                        {/* Customer Email */}
                        <div className="input-data">
                            <input type="text" required {...register("customer_email", {
                                required: {
                                    value: true,
                                    message: "שדה חובה"
                                }
                            })} />
                            <div className="underline"></div>
                            <label>אימייל לקוח</label>
                        </div>
                    </div>

                    <div className="form-row">
                        {/* Description */}
                        <div className="input-data">
                            <input type="text" required {...register("description", {
                                required: {
                                    value: true,
                                    message: "שדה חובה"
                                }
                            })} />
                            <div className="underline"></div>
                            <label>תיאור הזמנה</label>
                        </div>
                    </div>

                    <div className="form-row">
                        {/* Total Price */}
                        <div className="input-data">
                            <input type="text" required value={totalPrice} {...register("total_price", {
                                required: {
                                    value: true,
                                    message: "שדה חובה"
                                },
                                onChange: (e) => { handleTotalPriceChange(e) },
                            })} />
                            <div className="underline"></div>
                            <label>סכום כולל</label>
                        </div>
                        {/* Order Date */}
                        <div className="input-data">
                            <span className="date-label">תאריך ביצוע הזמנה</span>
                            <DatePicker
                                selected={orderDate}
                                onChange={(date) => (setOrderDate(date))}
                                dateFormat="dd/MM"
                                className="react-datepicker__input-container"
                            />
                            <input
                                type="hidden"
                                {...register("order_date", { required: true })}
                                value={orderDate.toISOString().split("T")[0]}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        {/* Is Paid */}
                        <div className="input-checkbox">
                            <label>האם שולם</label><br />
                            <input type="checkbox" className="checkbox-style" {...register("is_paid")} />
                        </div>
                        <br />
                        {/* Is Transfer */}
                        <div className="input-checkbox">
                            <label>האם צריך לעבור מסניף אחר</label><br />
                            <input type="checkbox" className="checkbox-style" onClick={() => handleTransferChange()} {...register("transfer")} />
                        </div>
                    </div>

                    {!isTransferChecked ? <div className="padding-row"></div> : null}
                    <div className={isTransferChecked ? "form-row" : "hidden"}>
                        {/* Transfer Branch */}
                        <div className="input-data">
                            <select required className="select-style" {...register("transfer_branch", {
                                required: {
                                    value: true,
                                    message: "שדה חובה"
                                }
                            })}>
                                <option value="1">ביג</option>
                                <option value="2">דיסק קלאב</option>
                                <option value="3">אייסמול</option>
                                <option value="4">רד</option>
                                <option value="5">טיילת</option>
                                <option value="6">משלוח לבית הלקוח באילת</option>
                                <option value="7">משלוח לבית הלקוח </option>
                                <option value="8">מחסן ראשי </option>
                            </select>
                            <label>סניף שולח</label>
                        </div>
                        {/* Is Transferred */}
                        <div className="input-checkbox">
                            <label>האם הועבר</label><br />
                            <input type="checkbox" className="checkbox-style" {...register("is_transferred")} />
                        </div>
                    </div>

                    <div className="form-row">
                        {/* Is Ready For Pickup */}
                        <div className="input-checkbox">
                            <label>האם מוכן לאיסוף</label><br />
                            <input type="checkbox" className="checkbox-style larger" {...register("is_ready_for_pickup")} />
                        </div>
                    </div>
                    <div className="form-row">
                        <br />
                        <div className="input-data">
                            <button type="submit" className="button-29" onClick={handleSubmit((data) => send(data))}>עדכן</button>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div >
                        <div className="input-data">
                            <button type="button" className="delete-button" onClick={wipeOrder}>מחק הזמנה</button>
                        </div>
                    </div>
                </form>
            </div>
        </div >
    );
}

export default EditOrder;
